import { Link } from "react-router-dom";
import axiosInstance from "./axiosInstance";

const MainMenu = () => {
	const redirectFileDownload = () => {
		axiosInstance
			.post("api/download-redirects", {
				responseType: "blob", // Указываем, что ожидаем файл
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "redirects.txt");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch((error) => {
				console.error("Ошибка при скачивании:", error);
			});
	};

	return (
		<div className="d-flex flex-column gap-2 mb-2">
			<div>
				<Link to={`/campaigns`} className="btn btn-secondary">
					Рекламные кампании
				</Link>
			</div>
			<div>
				<Link to={`/statistics`} className="btn btn-secondary">
					Статистика по рекламным кампания
				</Link>
			</div>
			<div>
				<Link to={`/statistics_erid`} className="btn btn-warning">
					Статистика по маркировке
				</Link>
			</div>
			<div>
				<Link to={`/statistics_ig`} className="btn btn-info">
					Статистика по пользователям
				</Link>
			</div>
			<div>
				<button className="btn btn-outline-warning" onClick={redirectFileDownload}>
					<i className="bi bi-download"></i> Скачать файл с редиректами
				</button>
			</div>
		</div>
	);
};

export default MainMenu;
