import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { API_BASE_URL } from "./apiConfig";
import { NavLink } from "react-router-dom";
import { getPostsFromTelegram } from "./functions/tg_functions";
import { getGoalIdFromMetric } from "./functions/metric_functions";
import { getErid, getTitleFromText } from "./functions/common_functions";
import { getPostsFromVK } from "./functions/vk_functions";
import TGAuth from "./TGAuth";

function formatDate(date) {
	if (typeof date === "string") {
		date = new Date(date);
	}

	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");

	return `${year}-${month}-${day}`;
}

const StatisticsErid = ({}) => {
	const [token, setToken] = useState("");
	const [counters, setCounters] = useState([]);
	const [selectedCounter, setSelectedCounter] = useState(90102071);
	const [goals, setGoals] = useState([]);
	const [statistics, setStatistics] = useState(null);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [metrics, setMetrics] = useState(`ym:pv:pageviews`);
	const [filters, setFilters] = useState(``);
	const [dimensions, setDimensions] = useState("ym:pv:URLPath");
	const [metricsArrFromYandex, setMetricsArrFromYandex] = useState([]);
	const [loadingStatistics, setLoadingStatistics] = useState(false);
	const [group, setGroup] = useState("day");
	const [links, setLinks] = useState([]);
	const [radioBtnValue, setRadioBtnValue] = useState("metrics");
	const [posts, setPosts] = useState([]);
	const [domen, setDomen] = useState("");
	const [statTitle, setStatTitle] = useState("");
	const [session, setSession] = useState(true);
	const [pageToken, setPageToken] = useState("");
	const [manualMode, setManualMode] = useState(false);

	const FB = window.FB;

	//ym:s:cross_device_firstDirectClickOrderName==${selectedCampain ? campaigns.find((c) => c.Id * 1 === selectedCampain)?.Name : "''"}`

	const metricsParams = {
		reaches: "Достижения",
		visits: "Визиты",
		bounceRate: "Показатель отказов",
		pageDepth: "Глубина просмотра",
		avgVisitDurationSeconds: "Средняя продолжительность визита (сек)",
		// и т.д.
	};

	const fetchCounters = async () => {
		try {
			const response = await axiosInstance.get("api/yandex/counters", {
				headers: { token },
			});
			return response.data.counters;
		} catch (error) {
			setErrorMessage("Error fetching counters");
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const response = await fetchCounters();
				setCounters(response);
			} catch (error) {
				console.log(error);
			}
		})();
	}, []);

	const fetchGoals = async (counterId) => {
		try {
			const response = await axiosInstance.get(`api/yandex/counters/${counterId}/goals`, {
				headers: { token },
			});
			setGoals(response.data.goals);
		} catch (error) {
			setErrorMessage(error.message);
		}
	};

	const fetchStatistics = async (startDateParam, endDateParam, manualMode = false) => {
		let links = [];
		setLoadingStatistics(true);
		const newStat = {
			head: [
				{ title: "Дата", name: "date" },
				{ title: "Erid", name: "erid" },
				{ title: "Заголовок", name: "title" },
				// { title: "Текст", name: "text" },
				{ title: "Просмотры", name: "views" },
			],
			data: [],
		};
		if (radioBtnValue === "metrics") {
			try {
				setErrorMessage("");
				console.log("metrics", selectedCounter);

				if (selectedCounter * 1 === 90102071) {
					//для основного сайта
					links = await getPostsRss("2023-09-01", endDateParam);
					setStatTitle(`Сайт: Статистика просмотров за ${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
					setDomen("https://carolinaspb.ru");
				} else if (selectedCounter * 1 === 95911363) {
					setStatTitle(`Медентика: Статистика просмотров за ${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
					links = await getPostsRss("2023-09-01", endDateParam, "https://medentika.shop");
					setDomen("https://medentika.shop");
				} else if (selectedCounter * 1 === 96536298) {
					setFilters("ym:pv:URLPath=@'/info/news/'");
					setStatTitle(`ИМ: Статистика просмотров за ${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
					links = await getPostsRss("2023-09-01", endDateParam, "https://carolinashop.ru");
					setDomen("https://carolinashop.ru");
				} else {
					setStatTitle(`СЧетчик ${selectedCounter}: Статистика просмотров за ${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
				}
				const dimensionsArray = dimensions.split(",").map((item) => item.trim());
				const allResponses = [];

				// Для каждой группировки создаем отдельный запрос
				await Promise.all(
					dimensionsArray.map(async (dimension) => {
						try {
							// Общий запрос без учета выбранных целей
							const res = await axiosInstance.get("api/yandex/statistics", {
								params: {
									counterId: selectedCounter,
									startDate: startDateParam,
									endDate: endDateParam,
									metrics,
									filters: filters,
									dimensions: dimension,
									group,
									limit: 10000,
									accuracy: "full",
								},
							});
							const goalStats = res.data.goalStats;
							const dataArr = goalStats.data;
							allResponses.push({ data: { goalStats: goalStats } });
							if (dataArr.length) {
								allResponses[allResponses.length - 1].data.goalStats.data = dataArr;
							}
						} catch (error) {
							console.log(error);
							setErrorMessage(error?.response?.data?.message);
						}
					})
				);
				await sleep(1000);
				console.log("links", links);

				if (links.length) {
					const linksStat = links
						.map((link) => {
							if (allResponses.goalStats) {
								return allResponses.goalStats.data.find((res) => link === domen + res.dimensions[0].name);
							} else {
								return allResponses[0].data.goalStats.data.find((res) => link === domen + res.dimensions[0].name);
							}
						})
						.filter((item) => item);
					console.log("linksStat", linksStat);
					console.log("allResponses", allResponses);

					const statistics = allResponses
						.map((response) => response.data.goalStats)
						.map((item) => {
							item.data = linksStat;
							return item;
						});
					statistics[0].data.forEach((item) => {
						const link = domen + item.dimensions[0].name;
						console.log("link", link);

						const post = posts.find((post) => post.link === link);
						console.log("post", post);
						newStat.data.push({
							link,
							views: item.metrics[0],
							title: post?.title,
							erid: post?.erid,
							date: new Date(post?.date).toLocaleDateString(),
							text: post?.text,
						});
					});
					// console.log(newStat);
					const filtredData = newStat.data.filter((item) => item.erid);
					newStat.data = filtredData;
					console.log("newStat", newStat);

					setStatistics(newStat);
				} else {
					const statistics = allResponses.map((response) => response.data.goalStats);
					console.log("statistics", statistics);
					statistics[0].data.forEach((item) => {
						const link = domen + item.dimensions[0].name;
						newStat.data.push({
							link,
							views: item.metrics[0],
							title: item.dimensions[0].name,
							erid: "cxv",
							date: "cxv",
							text: "cxvcxv",
						});
					});
					console.log("newStat", newStat);
					setStatistics(newStat);
				}

				setMetricsArrFromYandex(
					allResponses.map((response) =>
						response.data.goalStats?.query?.metrics?.map((metric) => {
							const goalId = getGoalIdFromMetric(metric);
							if (goalId) {
								const metricParam = metric.replace(`ym:s:goal${goalId}`, "");
								return metricsParams[metricParam];
							} else {
								const regexOther = /ym:s:(\w+)/;
								let match = metric.match(regexOther);
								if (match) {
									const metricParam = match[1];
									return metricsParams[metricParam] || metricParam;
								}
							}
							return metric;
						})
					)
				);
				setLoadingStatistics(false);
			} catch (error) {
				console.log(error);
				setErrorMessage(error?.response?.data?.message);
				setLoadingStatistics(false);
			}
		} else if (radioBtnValue === "VK") {
			try {
				const posts = await getPostsFromVK(startDateParam, endDateParam, manualMode);
				const postsLength = posts.length;

				if (postsLength) {
					posts.forEach((post) => {
						console.log(post);

						newStat.data.push({
							link: post.link,
							views: post.views[endDate.substring(0, 7)]?.count || 0,
							title: getTitleFromText(post.text),
							erid: getErid(post.text),
							date: post.date,
							// text: post.text,
						});
					});
					const filtredData = newStat.data.filter((item) => item.erid);
					newStat.data = filtredData;
					// console.log(newStat);
					setStatistics(newStat);
					setStatTitle(`VK_Статистика_просмотров_за_${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
					setLoadingStatistics(false);
				} else {
					setErrorMessage("Нет данных");
					setLoadingStatistics(false);
				}
			} catch (error) {
				console.log(error);
				setErrorMessage(error?.response?.data?.message);
				setLoadingStatistics(false);
			}
		} else if (radioBtnValue === "Telegram") {
			try {
				const posts = await getPostsFromTelegram(startDateParam, endDateParam, manualMode);
				const postsLength = posts.length;

				if (postsLength) {
					posts
						.filter((post) => post.views)
						.forEach((post) => {
							newStat.data.push({
								link: post.link,
								views: post.views[endDate.substring(0, 7)]?.count,
								title: getTitleFromText(post.text),
								erid: getErid(post.text),
								date: post.date,
								date_ms: post.date_ms,
								// text: post.text,
							});
						});
					const filtredData = newStat.data.filter((item) => item.erid).sort((a, b) => b.date_ms - a.date_ms);
					newStat.data = filtredData;
					// console.log(filtredPostsByDate);
					setStatistics(newStat);
					setStatTitle(`Telegram_Статистика_просмотров_за_${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
					setLoadingStatistics(false);
				} else {
					setErrorMessage("Нет данных");
					setLoadingStatistics(false);
				}
			} catch (error) {
				console.log(error);
				setErrorMessage(error?.response?.data?.message);
				setLoadingStatistics(false);
			}
		} else if (radioBtnValue === "Facebook" || radioBtnValue === "Instagram") {
			try {
				const posts = radioBtnValue === "Facebook" ? await getFBFeed(startDateParam, endDateParam, manualMode) : await getIGFeed(startDateParam, endDateParam, manualMode);
				// console.log("posts", posts);
				const postsLength = posts.length;

				if (postsLength) {
					posts
						.filter((post) => post.views)
						.forEach((post) => {
							newStat.data.push({
								link: post.link,
								views: post.views[endDate.substring(0, 7)]?.count || 0,
								title: getTitleFromText(post.text),
								erid: getErid(post.text),
								date: post.date,
								date_ms: post.date_ms,
								// text: post.text,
							});
						});
					const filtredData = newStat.data.filter((item) => item.erid).sort((a, b) => b.date_ms - a.date_ms);
					newStat.data = filtredData;
					// console.log(filtredPostsByDate);
					setStatistics(newStat);
					setStatTitle(`${radioBtnValue}_Статистика_просмотров_за_${new Date(startDate).toLocaleDateString()}-${new Date(endDate).toLocaleDateString()}`);
					setLoadingStatistics(false);
				} else {
					setErrorMessage("Нет данных");
					setLoadingStatistics(false);
				}
			} catch (error) {
				console.log(error);
				setErrorMessage(error?.response?.data?.message);
				setLoadingStatistics(false);
			}
		}
	};

	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	const fetchPreviousMonthStatistics = async (manual = false) => {
		if (manual) {
			setManualMode(true);
		}

		setLoadingStatistics(true);
		const now = new Date();
		const startOfPrevMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		const endOfPrevMonth = new Date(now.getFullYear(), now.getMonth(), 0);

		const prevMonthStartDate = formatDate(startOfPrevMonth);
		const prevMonthEndDate = formatDate(endOfPrevMonth);

		setStartDate(prevMonthStartDate);
		setEndDate(prevMonthEndDate);

		await fetchStatistics(prevMonthStartDate, prevMonthEndDate, manual);

		if (manual) {
			await sleep(5000);
			setManualMode(false);
		}

		setLoadingStatistics(false); // Завершаем загрузку статистики
	};

	const onCounterSelect = (e) => {
		const counterId = e.target.value;
		setSelectedCounter(counterId);
		fetchGoals(counterId);
	};

	const downloadReport = async () => {
		try {
			const response = await axiosInstance.post("api/report", {
				statistics,
				title: statTitle,
			});

			if (response.data.downloadUrl) {
				const link = document.createElement("a");
				link.href = API_BASE_URL + response.data.downloadUrl;
				link.download = `Отчет с ${startDate} по ${endDate}.docx`;
				document.body.appendChild(link);
				// console.log("link", link);
				link.click();
				document.body.removeChild(link);
			}
		} catch (error) {
			console.error("Error downloading report:", error);
		}
	};

	async function checkSession() {
		const response = await axiosInstance.post("api/telegram/session");
		if (response.data.session) {
			return response.data.session;
		} else {
			return false;
		}
	}

	const handleRadioChange = async (event) => {
		setRadioBtnValue(event.target.value);
		if (event.target.value === "Telegram") {
			const session = await checkSession();
			if (session) {
				setSession(session);
			} else {
				setSession(false);
			}
		} else {
			setSession(true);
		}
	};

	async function getPostsRss(startDate, endDate, source) {
		// Преобразование startDate и endDate в объекты типа Date, если они не являются таковыми
		if (!(startDate instanceof Date)) {
			startDate = new Date(startDate);
		}
		if (!(endDate instanceof Date)) {
			endDate = new Date(endDate);
		}

		try {
			const { data } = await axiosInstance.get("api/rss", {
				params: {
					limit: 10,
					source,
				},
			});
			console.log("data", data);

			if (data.rss?.channel?.item?.length) {
				const posts = data.rss?.channel?.item
					.map((post) => {
						return {
							title: post.title,
							date: new Date(post.pubDate),
							text: post.description,
							erid: getErid(post.description),
							link: post.link,
						};
					})
					.filter((post) => post.date.getTime() >= startDate.getTime() && post.date.getTime() <= endDate.getTime());
				console.log("posts", posts);

				setPosts(posts);
				const links = posts.map((post) => post.link);
				setLinks(links);
				return links;
			} else {
				console.error("Failed to");
			}
		} catch (error) {
			console.error("Error fetching posts:", error);
			return 0;
		}
	}

	async function getFBToken() {
		const response = await axiosInstance.post("api/facebook/token");
		console.log(response);
		return response.data.access_token;
	}

	async function getFBLongLivedPageToken(token) {
		if (token) {
			const response = await axiosInstance.post("api/facebook/longtoken", {
				token,
			});
			console.log(response);
			//это мы уже сразу получим токен страницы, функцию ниже getFBPageToken закоментил
			setPageToken(response.data.access_token);
			setSession(true);
		} else {
			return false;
		}
	}

	// async function getFBPageToken() {
	// 	if (longLiveToken) {
	// 		const response = await axiosInstance.post("api/facebook/pagetoken", {
	// 			token: longLiveToken,
	// 		});
	// 		console.log(response);
	// 		setPageToken(response.data.access_token);
	// 	} else {
	// 		return false;
	// 	}
	// }

	async function getFBFeed(startDate, endDate, manual = false) {
		const response = await axiosInstance.post(`api${manual ? "/manual" : ""}/facebook/feed`, {
			token: pageToken,
			startDate,
			endDate,
		});
		// console.log(response);
		return response.data;
		// return response.data.access_token;
	}

	async function getIGFeed(startDate, endDate, manual = false) {
		const response = await axiosInstance.post(`api${manual ? "/manual" : ""}/instagram/feed`, {
			token: pageToken,
			startDate,
			endDate,
		});
		// console.log(response);
		return response.data;
		// return response.data.access_token;
	}

	useEffect(() => {
		if (radioBtnValue === "Facebook" || radioBtnValue === "Instagram") {
			FB.getLoginStatus((response) => {
				console.log(response);
				if (response.status === "connected") {
					setSession(true);
					setToken(response.authResponse?.accessToken);
				} else {
					setSession(false);
				}
			});
		}
	}, [radioBtnValue]);

	return (
		<div className="container">
			<h1 className="mt-4">Статистика для отчетности по маркировке рекламы</h1>
			{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
			<div>
				<NavLink to="/" className="btn btn-secondary-outline mb-2">
					<i className="bi bi-arrow-left"></i>
					Назад в главное меню
				</NavLink>
			</div>
			<div className="mb-3">
				<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
					<input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" value="metrics" checked={radioBtnValue === "metrics"} onChange={handleRadioChange} />
					<label className="btn btn-outline-primary" htmlFor="btnradio1">
						Метрика
					</label>

					<input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" value="VK" checked={radioBtnValue === "VK"} onChange={handleRadioChange} />
					<label className="btn btn-outline-primary" htmlFor="btnradio2">
						ВК
					</label>

					<input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" value="Telegram" checked={radioBtnValue === "Telegram"} onChange={handleRadioChange} />
					<label className="btn btn-outline-primary" htmlFor="btnradio3">
						Телеграм
					</label>

					<input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" value="Facebook" checked={radioBtnValue === "Facebook"} onChange={handleRadioChange} />
					<label className="btn btn-outline-primary" htmlFor="btnradio4">
						Facebook
					</label>
					<input type="radio" className="btn-check" name="btnradio" id="btnradio5" autoComplete="off" value="Instagram" checked={radioBtnValue === "Instagram"} onChange={handleRadioChange} />
					<label className="btn btn-outline-primary" htmlFor="btnradio5">
						Instagram
					</label>
				</div>
			</div>

			{radioBtnValue === "metrics" && (
				<div>
					<div className="mb-3">
						<label htmlFor="counter" className="form-label">
							Счетчик
						</label>
						<select className="form-select" id="counter" value={selectedCounter} onChange={onCounterSelect}>
							<option value="">Выберите счетчик</option>
							{counters.map((counter) => (
								<option key={counter.id} value={counter.id}>
									{counter.name}
								</option>
							))}
						</select>
					</div>
				</div>
			)}

			{(radioBtnValue === "Telegram" || radioBtnValue === "VK") && (
				<div className="mb-3">
					<span className="text-danger">Пока только для основного сообщества Каролины</span>
				</div>
			)}
			{(radioBtnValue === "Facebook" || radioBtnValue === "Instagram") &&
				(!session ? (
					<div className="mb-3">
						<button
							className="btn btn-primary"
							onClick={() =>
								FB.login(
									(r) => {
										console.log(r);
										setToken(r.authResponse?.accessToken);
										getFBLongLivedPageToken(r.authResponse?.accessToken);
									},
									{ scope: "public_profile,email,pages_read_engagement,read_insights,pages_show_list,business_management,instagram_basic,instagram_manage_insights" }
								)
							}
						>
							Залогиниться
						</button>
					</div>
				) : (
					<button
						className="btn btn-primary mb-2"
						onClick={() => {
							FB.logout((r) => {
								// console.log(r);
								if (r.status !== "connected") {
									setSession(false);
								}
							});
						}}
					>
						Разлогиниться
					</button>
				))}

			{session ? (
				<>
					<div className="mb-3">
						<label htmlFor="startDate" className="form-label">
							Дата начала
						</label>
						<input type="date" className="form-control" id="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
					</div>
					<div className="mb-3">
						<label htmlFor="endDate" className="form-label">
							Дата окончания
						</label>
						<input type="date" className="form-control" id="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
					</div>
					{errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
					{loadingStatistics ? (
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Загрузка...</span>
						</div>
					) : (
						<div className="mb-2">
							<button className="btn btn-primary m-2" onClick={() => fetchPreviousMonthStatistics(false)}>
								Получить статистику за предыдущий месяц
							</button>

							{/* <button className="btn btn-secondary m-2" onClick={() => fetchStatistics(startDate, endDate)}>
    Получить статистику за выбранный период
</button> */}

							<button className="btn btn-danger m-2" onClick={() => fetchPreviousMonthStatistics(true)}>
								Получить статистику за предыдущий месяц (в ручном режиме - только если не отработало в обычном){" "}
							</button>

							<button className="btn btn-info m-2" disabled={!statistics ? true : false} onClick={downloadReport}>
								Скачать отчет в Word
							</button>
						</div>
					)}
					{console.log(statistics)}
					{statistics?.data?.length && group === "day" ? (
						<div>
							<h3>{statTitle}</h3>
							<table className="table table-striped table-bordered table-hover mt-4">
								<thead>
									<tr>
										{statistics.head?.map((h) => (
											<th key={h.name}>{h.title}</th>
										))}
									</tr>
								</thead>
								<tbody>
									{statistics.data?.map((item, rowIndex) => (
										<tr key={rowIndex}>
											{statistics.head?.map((h, index) => (
												<td key={index}>{h.name === "date" ? <a href={item.link}>{item[h.name]}</a> : item[h.name]}</td>
											))}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						""
					)}
				</>
			) : (
				<>{radioBtnValue === "Telegram" && <TGAuth setSession={setSession} />}</>
			)}
		</div>
	);
};

export default StatisticsErid;
